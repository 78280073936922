import { Injectable, signal } from '@angular/core';

interface StudentSelectionScope {
  id: number;
  fullName: string;
  academicYear: {
    id: number;
    name: string;
  };
  school: {
    id: number;
    name: string;
    class: {
      id: number;
      name: string;
    };
    level: {
      id: number;
      name: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class StudentSelectionScopeService {
  private readonly _studentSelectionScope = signal<
    Array<StudentSelectionScope>
  >([]);
  studentSelectionScope = this._studentSelectionScope.asReadonly();

  private readonly _selectedStudent = signal<StudentSelectionScope | null>(
    null,
  );
  selectedStudent = this._selectedStudent.asReadonly();

  constructor() {}

  updateStudentSelectionScope(students: Array<StudentSelectionScope>) {
    this._studentSelectionScope.set(students);
  }

  updateSelectedStudent(studentId: number) {
    const student = this._studentSelectionScope().find(
      (s) => s.id === studentId,
    );
    if (!student) {
      this._selectedStudent.set(null);
    } else this._selectedStudent.set(student);
  }
}
